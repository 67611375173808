<template>
  <Stockview>
    <div class="stockview-dashboard">
      <div class="columns is-desktop" v-if="seller && summary">
        <div class="column">
          <SalesOverview />
        </div>
        <div class="column">
          <PricingStats />
        </div>
      </div>
      <div class="columns is-desktop" v-if="seller && summary">
        <div class="column">
          <PricePosition />
        </div>
        <div class="column" v-if="permissions.marketDemand">
          <MarketDemand />
        </div>
      </div>
    </div>
  </Stockview>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'StockviewDashboard',
  components: {
    Stockview: () => import('./Stockview'),
    PricePosition: () => import('../components/StockviewPricePosition'),
    MarketDemand: () => import('../components/StockviewMarketDemand'),
    PricingStats: () => import('../components/StockviewPricingStats'),
    SalesOverview: () => import('../components/StockviewSalesOverview')
  },
  mounted() {
    this.$mxp.track('stockview_dashboard_page_load')
    const newSeller = !this.sellers.some(({ id }) => id === this.seller?.id)
    if (!this.summary || newSeller) this.load()
  },
  computed: {
    ...mapGetters('auth', ['sellers', 'permissions']),
    ...mapGetters('stockview/dashboard', ['summary', 'seller'])
  },
  methods: {
    ...mapActions('stockview/dashboard', ['load'])
  }
}
</script>
